import type { AxiosError, AxiosInstance } from 'axios';
import axios from 'axios';
import StoryblokClient from 'storyblok-js-client';
import { logInfo } from '@mop/shared/utils/logger';
import axiosRetry from 'axios-retry';
import { parseAxiosErrorResponseUrl } from '@mop/shared/utils/util';
import type { APIAwsSetupParams, APICmsSetupParams } from '@/types';
import type { ApiCmsInstance } from '@/types/cms';
import apiAws from '@/api/providers/aws';
import apiCms from '@/api/providers/cms';

const numberOfRetries = 1;
let apiAwsClient: AxiosInstance;

function retryCondition(error: AxiosError) {
  if (error.response?.status && error.response.status >= 500) {
    logInfo(`Retrying: ${parseAxiosErrorResponseUrl(error)}`);
    return true;
  }
  return false;
}

function getAwsClient(setupParams: APIAwsSetupParams) {
  if (!apiAwsClient) {
    apiAwsClient = axios.create({
      baseURL: setupParams.url,
      timeout: 30000,
      responseType: 'json',
    });
    axiosRetry(apiAwsClient, {
      retries: numberOfRetries,
      shouldResetTimeout: true,
      retryCondition,
    });
  }
  return apiAwsClient;
}

function createApiAws(setupParams: APIAwsSetupParams) {
  return apiAws(getAwsClient(setupParams));
}

function createApiCms(setupParams: APICmsSetupParams) {
  const cmsClient: ApiCmsInstance = {
    client: getCmsClient(setupParams),
    config: {
      cmsVersion: setupParams.cmsVersion,
      cmsLanguage: setupParams.cmsLanguage,
      cmsFallbackLanguage: setupParams.cmsFallbackLanguage,
      cmsRelease: setupParams.cmsRelease,
    },
  };

  return apiCms(cmsClient);
}

function getCmsClient(setupParams: APICmsSetupParams) {
  const client: StoryblokClient = new StoryblokClient({
    accessToken: setupParams.cmsApiKey,
    rateLimit: 1000,
    timeout: 10000,
    fetch: window.fetch.bind(window),
  });
  return client;
}

export { createApiAws, createApiCms };
